/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://tyju2e2z25etljkmplnv6xzusu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-otpfmfa2sjbijnu77gvq2v3lbe",
    "aws_cognito_identity_pool_id": "us-east-2:aafcac3e-5c13-41c2-836b-a182f5ef5aba",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_OpKcUzte7",
    "aws_user_pools_web_client_id": "54ikr1fdf45q1vgeh0sm6sbdpd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "BIRTHDATE",
        "FAMILY_NAME",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "judocoronado-storage-300f39ed22331-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
